import { templates } from '../templates/300';
import { useState } from 'react';

const useRandomWords = (size) => {
    const [randomWords, setRandomWords] = useState([]);

    function getPool(pool) {
        let maxSize = 300;
        let result = [];
        pool = pool.split(',');
        let randomIndex = Math.floor(Math.random() * maxSize);
        for (let i = 0; i < size; i++) {
            if (!pool[randomIndex]) continue;
            result.push(pool[randomIndex]);
            pool.splice(randomIndex, 1);
            maxSize -= 1;
            randomIndex = Math.floor(Math.random() * maxSize);
        }
        return result;
    }

    function getRandomWords(category) {
        switch (category) {
            case 'hard':
                setRandomWords(getPool(templates.hard));
                break;
            case 'verbs':
                setRandomWords(getPool(templates.verbs));
                break;
            case 'nouns':
                setRandomWords(getPool(templates.nouns));
                break;
            case 'adjectives':
                setRandomWords(getPool(templates.adjectives));
                break;
            case 'phrasal':
                setRandomWords(getPool(templates.phrasal));
                break;
            default:
                setRandomWords(getPool(templates.hard));
                break;
        }
    }

    return {
        randomWords,
        getRandomWords,
    };
};

export default useRandomWords;
