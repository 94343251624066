import { useContext, useState } from 'react';
import { ToDosDataContext } from '../contexts/ToDosDataContext';
import { Button, Card, Select } from 'antd';
import useRandomWords from '../hooks/useRandomWords';

const { Meta } = Card;

export const Generate = () => {
    const { words, setData } = useContext(ToDosDataContext);
    const { getRandomWords, randomWords } = useRandomWords(15);
    const [category, setCategory] = useState('hard');
    const [randomDescription, setRandomDescription] = useState(
        'Generate words within the category selected and click on them to feed your pool!'
    );

    return (
        <div className="word">
            <Card
                title="Need inspiration?"
                style={{ width: 350, textAlign: 'center' }}
            >
                {randomWords.map((word, index) => (
                    <Button
                        key={index}
                        className="randomButton"
                        onClick={() => {
                            setData([...words, word]);
                            randomWords.splice(index, 1);
                        }}
                    >
                        {' '}
                        {word}{' '}
                    </Button>
                ))}
                <Meta title="" description={randomDescription} />
            </Card>
            <br />
            <Button
                type="primary"
                onClick={() => {
                    setRandomDescription('');
                    getRandomWords(category);
                }}
            >
                Generate Words
            </Button>

            <br />

            <Select
                defaultValue={category}
                style={{
                    width: 120,
                }}
                onChange={(value) => setCategory(value)}
                options={[
                    {
                        value: 'verbs',
                        label: 'Verbs',
                    },
                    {
                        value: 'hard',
                        label: 'Hard words',
                    },
                    {
                        value: 'nouns',
                        label: 'Nouns',
                    },
                    {
                        value: 'adjectives',
                        label: 'Adjectives',
                    },
                    {
                        value: 'phrasal',
                        label: 'Phrasal verbs',
                    },
                ]}
            />
            <br />
            <br />
        </div>
    );
};
