import { useContext, useEffect, useState } from 'react';
import { ToDosDataContext } from '../contexts/ToDosDataContext';
import { UserContext } from '../contexts/UserContext';
import { Button, Card, Spin } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { Tags } from './Tags';
import { Generate } from './Generate';

export const Words = () => {
    const {
        words,
        createTodo,
        emailPreference,
        updateEmailPreference,
        setEmailPreference,
        toast,
        getData,
        loadingStatus,
    } = useContext(ToDosDataContext);
    console.log('emailPreference', emailPreference);
    const [emailPref, setEmailPref] = useState(emailPreference);
    const { user } = useContext(UserContext);
    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="word">
            <h2> Hello {user.name.split(' ')[0]} </h2>
            <Button
                type="primary"
                disabled={saveLoading}
                onClick={() => {
                    setSaveLoading(true);
                    updateEmailPreference(!emailPreference, () => {
                        toast(`Your email preference has been changed  🛹`);
                        setSaveLoading(false);
                        setEmailPreference(!emailPreference);
                    });
                }}
            >
                <BellOutlined /> {emailPreference ? 'Enable' : 'Disable'} Daily
                Email
            </Button>
            <br />
            <Card
                title="Your words pool "
                style={{ width: 300, textAlign: 'center' }}
            >
                <div className="loading">
                    {loadingStatus === 'loading' ? <Spin /> : ''}{' '}
                </div>
                {words && loadingStatus !== 'loading' ? (
                    <Tags words={words} />
                ) : (
                    ''
                )}
            </Card>
            <br />

            <Button
                type="primary"
                disabled={saveLoading}
                onClick={() => {
                    setSaveLoading(true);
                    createTodo(words, () => {
                        toast('Your words have been saved! Happy learning 🛹');
                        setSaveLoading(false);
                    });
                }}
            >
                Save Words
            </Button>

            <br />

            <Generate />
        </div>
    );
};
