import './App.css';
import { Layout } from './layout/Layout';
import { TodosDataProvider } from './contexts/ToDosDataContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { UserProvider } from './contexts/UserContext';

function App() {
    return (
        <UserProvider>
            <ThemeProvider>
                <TodosDataProvider>
                    <Layout></Layout>
                </TodosDataProvider>
            </ThemeProvider>
        </UserProvider>
    );
}

export default App;
