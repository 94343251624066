import React, { createContext, useState } from 'react';
import { app } from '../config/firebase';

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
    const localUser = JSON.parse(localStorage.getItem('user'));
    const [user, setUser] = useState(
        localUser ?? { name: '', email: '', token: '' }
    );

    const value = {
        user,
        setUser,
    };

    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
};
