import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';

const LOADING_STATES = ['loading', 'errored', 'success'];

const useGeneralizedCrudMethods = (errorNotificationFn) => {
    const [data, setData] = useState();
    const [emailPreference, setEmailPreference] = useState();
    const [error, setError] = useState();
    const [loadingStatus, setLoadingStatus] = useState('loading');

    const { user } = useContext(UserContext);

    /**
     * get data on context creation
     */
    useEffect(() => {
        async function getDataUseEffect() {
            try {
                setLoadingStatus(LOADING_STATES[0]);
                const results = await axios.get(
                    `https://getwords-3rmhwzhsyq-uc.a.run.app?id=${user.email}`
                );
                setData(results.data.wordList);
                setLoadingStatus(LOADING_STATES[2]);
            } catch (e) {
                console.log('Error??', e);
                setError(e);
                setLoadingStatus(LOADING_STATES[1]);
            }
        }
        // getDataUseEffect();
    }, []);

    /**
     * get Words on login
     */
    async function getData() {
        try {
            setLoadingStatus(LOADING_STATES[0]);
            const results = await axios.get(
                `https://getwords-3rmhwzhsyq-uc.a.run.app?id=${user.email}`
            );
            console.log('results', results.data);
            setData(results.data.wordList ?? []);
            setEmailPreference(results.data.disabled ?? true);
            setLoadingStatus(LOADING_STATES[2]);
            return results;
        } catch (e) {
            console.log('Error??', e);
            setError(e);
            setLoadingStatus(LOADING_STATES[1]);
        }
    }

    function createRecord(createObject, callbackDone) {
        async function addData() {
            try {
                setData(createObject);
                await axios.post(`https://addwords-3rmhwzhsyq-uc.a.run.app`, {
                    words: createObject,
                    id: user.email,
                });
                if (callbackDone) callbackDone();
            } catch (e) {
                const errorString = e;
                errorNotificationFn?.(errorString);
                if (callbackDone) callbackDone();
            }
        }
        addData();
    }

    function updateEmailPreference(emailPreference, callbackDone) {
        async function updateEmailPreference() {
            try {
                await axios.post(
                    `https://disableemail-3rmhwzhsyq-uc.a.run.app`,
                    {
                        disableEmail: emailPreference,
                        id: user.email,
                    }
                );
                if (callbackDone) callbackDone();
            } catch (e) {
                const errorString = e;
                errorNotificationFn?.(errorString);
                if (callbackDone) callbackDone();
            }
        }
        updateEmailPreference();
    }

    return {
        getData,
        data, // returned data after loadingStatus === "success"
        setData,
        loadingStatus, // "success", "errored", "loading"
        error, // error string
        createRecord, // creates new record at end, takes first record as parameter, second as callback function when done
        updateEmailPreference,
        emailPreference,
        setEmailPreference,
    };
};

export default useGeneralizedCrudMethods;
