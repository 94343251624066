import useGeneralizedCrudMethods from './useGeneralizedCrudMethods';

const useTodosData = () => {
    const errorNotificationFn = (error) => {
        console.log('Error From useTodosData', error);
    };

    const {
        getData,
        data,
        setData,
        error,
        loadingStatus,
        createRecord,
        updateEmailPreference,
        emailPreference,
        setEmailPreference,
    } = useGeneralizedCrudMethods(errorNotificationFn);

    function createTodo(rec, callbackDone) {
        createRecord(rec, callbackDone);
    }

    return {
        getData,
        words: data,
        setData,
        loadingStatus,
        error,
        createTodo,
        updateEmailPreference,
        setEmailPreference,
        emailPreference,
    };
};

export default useTodosData;
