import React, { createContext } from 'react';
import useTodosData from '../hooks/userTodosData';

import toast, { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/blue-dusk.css';

toastConfig({ theme: 'blue-dusk' });

export const ToDosDataContext = createContext({
    words: [],
    createTodo: () => {},
    updateEmailPreference: () => {},
    loadingStatus: '',
});

export const TodosDataProvider = ({ children }) => {
    const {
        words,
        setData,
        createTodo,
        loadingStatus,
        getData,
        updateEmailPreference,
        emailPreference,
        setEmailPreference,
    } = useTodosData();

    const value = {
        getData,
        words,
        setData,
        createTodo,
        updateEmailPreference,
        emailPreference,
        setEmailPreference,
        loadingStatus,
        toast,
    };

    return (
        <ToDosDataContext.Provider value={value}>
            {children}
        </ToDosDataContext.Provider>
    );
};
