import { Header } from './Header';
import { Footer } from './Footer';
import { ConfigProvider, theme } from 'antd';
import { ThemeContext } from '../contexts/ThemeContext';
import { useContext } from 'react';
import { Login } from '../components/Login';
import { Words } from '../components/Words';
import { UserContext } from '../contexts/UserContext';

export const Layout = () => {
    const { darkTheme } = useContext(ThemeContext);
    const { user } = useContext(UserContext);
    return (
        <ConfigProvider
            theme={{
                algorithm: !darkTheme
                    ? theme.defaultAlgorithm
                    : theme.darkAlgorithm,
            }}
        >
            <div className={`container ${darkTheme ? 'darkBackground' : ''}`}>
                <Header />
                {user?.name === '' ? <Login /> : <Words />}

                <Footer />
            </div>
        </ConfigProvider>
    );
};
