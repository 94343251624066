import '../App.css';
import { Button } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { UserContext } from '../contexts/UserContext';
import { ToDosDataContext } from '../contexts/ToDosDataContext';
import { useContext } from 'react';
import { Timeline } from 'antd';

const provider = new GoogleAuthProvider();

export const Login = () => {
    const { setUser } = useContext(UserContext);

    function signIn() {
        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                const res = result.user;
                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        name: res.displayName,
                        email: res.email,
                    })
                );

                setUser({ name: res.displayName, email: res.email });
            })
            .catch((error) => {
                console.log('error ---> ', error);
            });
    }

    return (
        <div className="login">
            <h2> Welcome to Keepy </h2>
            <p> Improve your vocabulary </p>

            <br />
            <br />
            <br />

            <Timeline
                items={[
                    {
                        children: 'Sign In using your gmail account',
                    },
                    {
                        children:
                            'Put some words in your words pool (choose 10 or less, one step at a time)',
                    },
                    {
                        children:
                            "That's it! Check your email every morning to get your daily dose of vocabulary practice.",
                    },
                ]}
            />

            <Button
                type="primary"
                shape="round"
                icon={<GoogleOutlined />}
                size="large"
                onClick={() => signIn()}
            >
                Sign In
            </Button>
        </div>
    );
};
