import { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import { Switch } from 'antd';
import { UserContext } from '../contexts/UserContext';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ToDosDataContext } from '../contexts/ToDosDataContext';
import { getAuth, signOut } from 'firebase/auth';
import { BulbOutlined } from '@ant-design/icons';

export const Header = () => {
    const { toggleTheme } = useContext(ThemeContext);
    const { user, setUser } = useContext(UserContext);
    const { setData } = useContext(ToDosDataContext);
    const auth = getAuth();

    function signOutFlow() {
        signOut(auth)
            .then(() => {
                localStorage.removeItem('user');
                setUser({ name: '', email: '', token: '' });
                setData([]);
            })
            .catch((error) => {
                console.log('error --> ', error);
            });
    }
    return (
        <div className="header">
            <div className="logo">
                <img src="keepy.png"></img>
            </div>
            <div className="menu">
                <div className="changeTheme">
                    <BulbOutlined />
                    <Switch
                        onChange={() => {
                            toggleTheme();
                        }}
                    />
                </div>
                {user.email !== '' && (
                    <div className="logOut">
                        <Button
                            type="primary"
                            shape="round"
                            icon={<LogoutOutlined />}
                            size="auto"
                            onClick={() => signOutFlow()}
                        ></Button>
                    </div>
                )}
            </div>
        </div>
    );
};
