// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyB69P_5oXdgSwRC4S4Amzq4DfRnEYQogVc',
    authDomain: 'keeplearning-43de4.firebaseapp.com',
    projectId: 'keeplearning-43de4',
    storageBucket: 'keeplearning-43de4.appspot.com',
    messagingSenderId: '526502028922',
    appId: '1:526502028922:web:d79690d2d1485fc5ecbdc3',
    measurementId: 'G-7TXJWCSLZV',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
